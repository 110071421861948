'use client';

import { StytchB2BProvider } from '@stytch/nextjs/b2b';
import { createStytchB2BUIClient } from '@stytch/nextjs/b2b/ui';

import { SessionChecker } from './SessionChecker';

export const StytchWrapper = ({
  children,
  token,
}: {
  children: React.ReactNode;
  token: string;
}) => {
  const stytchClient = createStytchB2BUIClient(token);

  return (
    <StytchB2BProvider stytch={stytchClient}>
      {children}
      <SessionChecker />
    </StytchB2BProvider>
  );
};
