export const colors = {
  white: '#fff',
  black: '#000',
  blue: 'rgba(45, 182, 245, 1)',
  'blue-2': 'rgba(45, 182, 245, 0.2)',
  gray: {
    DEFAULT: 'rgba(148, 163, 179, 1)',
    '2': 'rgba(206, 207, 210, 1)',
    '3': 'rgba(148, 163, 179, 1)',
    '4': 'rgba(129, 129, 129, 1)',
    '6': 'rgba(88, 88, 94, 1)',
    '8': 'rgba(52, 56, 55, 1)',
    '9': 'rgba(47, 49, 46, 1)',
    '9-5': 'rgba(39, 40, 39, 1)',
    '10': 'rgba(30, 31, 30, 1)',
    '10-5': 'rgba(22, 22, 22, 1)',
    '11': 'rgba(17, 17, 17, 1)',
    '12': 'rgba(2, 2, 3, 1)',
    '13': 'rgb(38, 40, 39)',
    '14': 'rgb(22, 22, 22)',
    '15': 'rgba(12, 17, 29, 1)',
    '16': 'rgb(36, 36, 37)',
    '17': 'rgb(12, 12, 13)',
  },
  green: {
    DEFAULT: 'rgb(89, 204, 178)',
    muted: 'rgba(89, 204, 178, 0.18)',
  },
  pink: 'rgba(243, 104, 224, 1)',
  purple: 'rgba(80, 72, 229, 1)',
  red: 'rgb(248 34 36)',
  yellow: 'rgba(254, 202, 87, 1)',
  'chart-blue': 'rgba(45, 182, 245, 1)',
  'chart-gold': 'rgba(254, 202, 87, 1)',
  'chart-green': 'rgba(89, 204, 178, 1)',
  'chart-orange': 'rgba(242, 126, 50, 1)',
  'chart-pink': 'rgba(255, 197, 248, 1)',
  'chart-purple': 'rgba(159, 125, 225, 1)',
  'chart-red': 'rgba(248, 34, 36, 1)',
};
