'use client';

import { UTCDate } from '@date-fns/utc';
import { useStytchB2BClient, useStytchMemberSession } from '@stytch/nextjs/b2b';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { loginConfig } from '@/src/features/login/config';

export const SessionChecker = () => {
  const [reloadAt, setReloadAt] = useState<Date | null>(null);
  const stytch = useStytchB2BClient();
  const { session: memberSession, isInitialized } = useStytchMemberSession();
  const pathname = usePathname();

  useEffect(() => {
    if (memberSession && isInitialized) {
      const sessionDuration = loginConfig.sessionOptions.sessionDurationMinutes;
      stytch.session
        .exchange({
          organization_id: memberSession.organization_id,
          session_duration_minutes: sessionDuration,
        })
        .then((response) => {
          if (response.member_session?.expires_at) {
            setReloadAt(new UTCDate(response.member_session?.expires_at));
          }
        });
    }
  }, [pathname, memberSession, isInitialized]);

  useEffect(() => {
    if (reloadAt) {
      const timeout = setTimeout(() => {
        window.location.reload();
      }, reloadAt.getTime() - new UTCDate().getTime());
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [reloadAt]);

  return null;
};
