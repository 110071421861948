import {
  AuthFlowType,
  B2BProducts,
  StyleConfig,
  StytchB2BUIConfig,
} from '@stytch/vanilla-js/b2b';
import { AdminPortalStyleConfig } from '@stytch/vanilla-js/dist/adminPortal/AdminPortalStyleConfig';
import { PartialDeep } from 'type-fest';

import { BarlowFont } from '@/src/common/utils/barlow-font';
import { colors } from '@/themes/default/colors';

export const loginConfig: StytchB2BUIConfig = {
  authFlowType: AuthFlowType.Discovery,
  directLoginForSingleMembership: {
    status: true,
    ignoreInvites: true,
    ignoreJitProvisioning: true,
  },
  products: [B2BProducts.passwords],
  sessionOptions: {
    sessionDurationMinutes: 7 * 24 * 60,
  },
  passwordOptions: {
    resetPasswordTemplateId: 'reset_pass_2',
  },
  emailMagicLinksOptions: {
    loginTemplateId: 'template_4',
    signupTemplateId: 'template_5',
  },
};

export const loginStylesConfig: PartialDeep<StyleConfig> = {
  fontFamily: BarlowFont.style.fontFamily,
  container: {
    width: '500px',
  },
  buttons: {
    primary: {
      backgroundColor: colors.green.DEFAULT,
      textColor: colors.white,
      borderColor: 'transparent',
      borderRadius: '4px',
    },
    secondary: {
      backgroundColor: colors.gray[10],
      textColor: colors.white,
      borderColor: 'transparent',
      borderRadius: '4px',
    },
  },
};

export const stytchStylesConfig: PartialDeep<AdminPortalStyleConfig> = {
  container: {
    backgroundColor: colors.gray[11],
    borderColor: colors.gray[11],
  },
  colors: {
    primary: colors.white,
    secondary: colors.gray.DEFAULT,
    success: colors.green.DEFAULT,
    error: colors.red,
    accentText: colors.white,
    accent: colors.gray[8],
    subtle: colors.gray[11],
  },
  inputs: {
    backgroundColor: colors.gray[10],
    textColor: colors.white,
    placeholderColor: colors.gray[6],
    borderColor: `${colors.gray[10]} !important`,
    borderRadius: '4px',
  },
  buttons: {
    primary: {
      backgroundColor: colors.green.DEFAULT,
      textColor: colors.white,
      borderColor: 'transparent',
      borderRadius: '4px',
    },
    secondary: {
      backgroundColor: 'transparent',
      textColor: colors.white,
      borderColor: 'transparent',
      borderRadius: '4px',
    },
    disabled: {
      backgroundColor: colors.gray[8],
      textColor: colors.gray[6],
      borderColor: 'transparent',
      borderRadius: '4px',
    },
  },
  fontFamily: 'Barlow, sans-serif',
  borderRadius: '4px',
};
